.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
}

.body {
    flex: 1;
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/
    padding-bottom: 80px;
}

.bottom {
    position: fixed;
    z-index: 9999;
    bottom: 0;
    width: 100%;
    border-top: solid 1px var(--adm-color-border);
    background: white;
}